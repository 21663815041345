import { PropTypes } from 'prop-types';
import { VacationBalanceItem } from '../VacationBalanceItem';
import { formatNumber, getSumOfDataTillToday } from '../VacationAndSickLeave/utils';
import styles from './styles.module.scss';
import '../../App.scss';

const umbrellaIcon = `${process.env.PUBLIC_URL}/assets/vacation_balance_logo_1.png`;
const cardIcon = `${process.env.PUBLIC_URL}/assets/vacation_balance_logo_2.png`;
const listIcon = `${process.env.PUBLIC_URL}/assets/vacation_balance_logo_3.png`;
const downArrowIcon = `${process.env.PUBLIC_URL}/assets/vacation_balance_logo_4.png`;
const arrowIcon = `${process.env.PUBLIC_URL}/assets/vacation_balance_logo_5.png`;
const checkedIcon = `${process.env.PUBLIC_URL}/assets/vacation_balance_logo_6.png`;


export const VacationBalance = ({ vacationData }) => {
  const {
    currentVacationBalance,
    transferredBalance,
    minimumAllowedBalance,
    expectedEoyVacationBalance,
    yearData
  } = vacationData;

  const accuralData = getSumOfDataTillToday(yearData, 'accrual');
  const usedData = getSumOfDataTillToday(yearData, 'used');

  const availableVacationWithCredit = (
    +formatNumber(currentVacationBalance) - minimumAllowedBalance
  ).toFixed(2);

  return (
    <div className={styles['vacation-balance']}>
      <h3 className="vacation-and-sick-leave-section-title">Vacation balance, days</h3>

      <div className={styles['vacation-balance__content']}>
        <VacationBalanceItem
          data={formatNumber(currentVacationBalance)}
          title="Current balance"
          icon={umbrellaIcon}
          isCurrent={true}
        />

        <VacationBalanceItem
          data={availableVacationWithCredit}
          title="Available vacation with credit"
          icon={cardIcon}
          creditBallance={minimumAllowedBalance}
        />

        <VacationBalanceItem
          data={formatNumber(expectedEoyVacationBalance)}
          title="Expected EOY balance"
          icon={listIcon}
        />

        <VacationBalanceItem
          data={formatNumber(transferredBalance)}
          title="Transferred balance"
          icon={downArrowIcon}
        />

        <VacationBalanceItem
          data={accuralData}
          title="Earned to date"
          icon={arrowIcon}
        />

        <VacationBalanceItem
          data={usedData}
          title="Used to date"
          icon={checkedIcon}
        />
      </div>
    </div>
  );
};

VacationBalance.propTypes = {
  vacationData: PropTypes.shape({
    employmentDate: PropTypes.string,
    firstReportedDate: PropTypes.string,
    terminationDate: PropTypes.string,
    standingYears: PropTypes.number.isRequired,
    transferredBalance: PropTypes.number.isRequired,
    minimumAllowedBalance: PropTypes.number.isRequired,
    currentVacationBalance: PropTypes.number.isRequired,
    expectedEoyVacationBalance: PropTypes.number.isRequired,
    yearData: PropTypes.shape({
      january: PropTypes.shape({
        accrual: PropTypes.number.isRequired,
        used: PropTypes.number,
        usedUnpaid: PropTypes.number
      }),
      february: PropTypes.shape({
        accrual: PropTypes.number.isRequired,
        used: PropTypes.number,
        usedUnpaid: PropTypes.number
      }),
      march: PropTypes.shape({
        accrual: PropTypes.number.isRequired,
        used: PropTypes.number,
        usedUnpaid: PropTypes.number
      }),
      april: PropTypes.shape({
        accrual: PropTypes.number.isRequired,
        used: PropTypes.number,
        usedUnpaid: PropTypes.number
      }),
      may: PropTypes.shape({
        accrual: PropTypes.number.isRequired,
        used: PropTypes.number,
        usedUnpaid: PropTypes.number
      }),
      june: PropTypes.shape({
        accrual: PropTypes.number.isRequired,
        used: PropTypes.number,
        usedUnpaid: PropTypes.number
      }),
      july: PropTypes.shape({
        accrual: PropTypes.number.isRequired,
        used: PropTypes.number,
        usedUnpaid: PropTypes.number
      }),
      august: PropTypes.shape({
        accrual: PropTypes.number.isRequired,
        used: PropTypes.number,
        usedUnpaid: PropTypes.number
      }),
      september: PropTypes.shape({
        accrual: PropTypes.number.isRequired,
        used: PropTypes.number,
        usedUnpaid: PropTypes.number
      }),
      october: PropTypes.shape({
        accrual: PropTypes.number.isRequired,
        used: PropTypes.number,
        usedUnpaid: PropTypes.number
      }),
      november: PropTypes.shape({
        accrual: PropTypes.number.isRequired,
        used: PropTypes.number,
        usedUnpaid: PropTypes.number
      }),
      december: PropTypes.shape({
        accrual: PropTypes.number.isRequired,
        used: PropTypes.number,
        usedUnpaid: PropTypes.number
      })
    })
  }).isRequired
};
