import React from 'react';
import classNames from 'classnames';
import { countAverageValueByKey, countYearProgress } from './utils';
import { PropTypes } from 'prop-types';
import { CircleProgressbar } from '../CircleProgressBar';
import styles from './styles.module.scss';

export const YearProgress = ({
  selectedYear,
  selectedData,
  selectedOverallTab,
  onOverallTabChanged,
  onFilteredTabChanged,
  isFilterUnused
}) => {
  const yearProgress = React.useMemo(() => countYearProgress(selectedYear), [selectedYear]);

  const averageUsed = React.useMemo(
    () => countAverageValueByKey(selectedData, 'totalUsedPercent'),
    [selectedData]
  );

  const averageUsedPure = React.useMemo(
    () => countAverageValueByKey(selectedData, 'totalUsedPercentPure'),
    [selectedData]
  );

  return (
    <div className={styles['year-info-wrapper']}>
      <div className={styles['year-info']}>
        <div className={styles['year-info__tabs']}>
          <button
            className={classNames(styles['year-info__tab'], {
              [styles['year-info__tab--active']]: selectedOverallTab
            })}
            onClick={onOverallTabChanged}>
            Overall
          </button>

          <button
            className={classNames(styles['year-info__tab'], {
              [styles['year-info__tab--active']]: !selectedOverallTab
            })}
            onClick={onFilteredTabChanged}
            disabled={isFilterUnused}>
            Filtered
          </button>
        </div>

        <div className={styles['year-info__items']}>
          <div className={styles['year-info__item']}>
            <CircleProgressbar percentage={yearProgress} styles={{ width: 28, height: 28 }} />

            <div className={styles['year-info__number']}>
              <b>
                {yearProgress}
                <b className={styles['year-info__percentage']}>%</b>
              </b>

              <div className={styles['year-info__title']}>Of Year</div>
            </div>
          </div>

          <div className={styles['year-info__item']}>
            <CircleProgressbar percentage={averageUsed} styles={{ width: 28, height: 28 }} />

            <div className={styles['year-info__number']}>
              <b>
                {averageUsed}
                <b className={styles['year-info__percentage']}>%</b>
              </b>
              <div className={styles['year-info__title']}>Used</div>
            </div>
          </div>

          <div className={styles['year-info__item']}>
            <CircleProgressbar percentage={averageUsedPure} styles={{ width: 28, height: 28 }} />

            <div className={styles['year-info__number']}>
              <b>
                {averageUsedPure}
                <b className={styles['year-info__percentage']}>%</b>
              </b>
              <div className={styles['year-info__title']}>Used Pure</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

YearProgress.propTypes = {
  selectedData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      fullName: PropTypes.string.isRequired,
      apr: PropTypes.string,
      aug: PropTypes.string,
      dec: PropTypes.string,
      feb: PropTypes.string,
      jan: PropTypes.string,
      jul: PropTypes.string,
      jun: PropTypes.string,
      mar: PropTypes.string,
      may: PropTypes.string,
      nov: PropTypes.string,
      oct: PropTypes.string,
      sep: PropTypes.string,
      totalAtThisYear: PropTypes.string,
      totalUsed: PropTypes.number,
      totalUsedPercent: PropTypes.string,
      totalUsedPercentPure: PropTypes.string,
      expectedEoyVacationBalance: PropTypes.number
    })
  ),
  selectedYear: PropTypes.number.isRequired,
  selectedOverallTab: PropTypes.bool.isRequired,
  isFilterUnused: PropTypes.bool.isRequired,
  onOverallTabChanged: PropTypes.func.isRequired,
  onFilteredTabChanged: PropTypes.func.isRequired
};
