import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';
import styles from './styles.module.scss';

export const YearSelector = ({ onSelectedYearChanged, withBorder, selectedYear }) => {
  const currentYear = new Date().getFullYear();
  const [year, setYear] = useState(selectedYear);

  const onNextYearClicked = () => {
    setYear((prevYear) => (prevYear < currentYear ? prevYear + 1 : prevYear));
    onSelectedYearChanged(year < currentYear ? year + 1 : year);
  };

  const onPrevYearClicked = () => {
    setYear((prevYear) => (prevYear > 2018 ? prevYear - 1 : 2018));
    onSelectedYearChanged(year > 2018 ? year - 1 : 2018);
  };

  return (
    <div
      className={classNames(styles['year-selector'], {
        [styles['year-selector--with-border']]: withBorder
      })}>
      <button
        className={classNames(styles['year-selector__button'], styles['year-selector__icon'])}
        onClick={onPrevYearClicked}
        disabled={year === 2018}>
        <i className="pi pi-angle-left" />
      </button>

      <button className={styles['year-selector__button']}>
        <span>{year}</span>
      </button>

      <button
        className={classNames(styles['year-selector__button'], styles['year-selector__icon'])}
        onClick={onNextYearClicked}
        disabled={year === currentYear}>
        <i className="pi pi-angle-right" />
      </button>
    </div>
  );
};

YearSelector.propTypes = {
  onSelectedYearChanged: PropTypes.func.isRequired,
  withBorder: PropTypes.bool,
  selectedYear: PropTypes.number
};
