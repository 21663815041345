import { PropTypes } from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import { defineLabel } from './utils';
import styles from './styles.module.scss';
import '../../App.scss';

const leoImage = `${process.env.PUBLIC_URL}/assets/leo.svg`;

export const VacationGeneralInformation = ({
  standingYears,
  employmentDate,
  firstReportedDate,
  terminationDate
}) => {
  const label = defineLabel(employmentDate, standingYears);
  const startEmploymentDay = employmentDate || firstReportedDate;

  return (
    <div className={styles['general-information']}>
      <h3 className="vacation-and-sick-leave-section-title">General information</h3>

      <div className={styles['general-information__content']}>
        <div
          className={classNames(styles['standing-years'], {
            [styles['standing-years--1-2']]: standingYears >= 1 && standingYears <= 2,
            [styles['standing-years--3-5']]: standingYears >= 3 && standingYears <= 5,
            [styles['standing-years--6-9']]: standingYears >= 6 && standingYears <= 9,
            [styles['standing-years--10']]: standingYears >= 10
          })}>
          {standingYears === 0 ? (
            <div className={styles['standing-years__without-background']} />
          ) : (
            <img
              className={styles['standing-years__background']}
              src={leoImage}
              alt="leo_background"
            />
          )}

          <div
            className={classNames({
              [styles['trial-or-TM-label']]: label
            })}>
            {label}
          </div>

          <div className={styles['standing-years__info']}>
            <span
              className={classNames(styles['standing-years__number'], {
                [styles['standing-years__number--trial-or-TM-label']]: label
              })}>
              {standingYears}
            </span>

            <span className={styles['standing-years__title']}>Standing, years</span>
          </div>
        </div>

        <div className={styles['employment-date']}>
          <div
            className={classNames(styles['employment-date__time'], {
              [styles['employment-date__time--with-termination']]: terminationDate
            })}>
            {startEmploymentDay ? moment(startEmploymentDay).format('L') : 'N/A'}
            {terminationDate && ` – ${moment(terminationDate).format('L')}`}
          </div>

          <div className={styles['employment-date__title']}>
            Employment date{terminationDate && ' – termination dates'}
          </div>
        </div>
      </div>
    </div>
  );
};

VacationGeneralInformation.propTypes = {
  standingYears: PropTypes.number.isRequired,
  employmentDate: PropTypes.string,
  firstReportedDate: PropTypes.string,
  terminationDate: PropTypes.string
};
