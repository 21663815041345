import VacationAndSickLeaveTable from '../VacationAndSickLeaveTable';
import { VacationBalance } from '../VacationBalance';
import { VacationGeneralInformation } from '../VacationGeneralInformation';
import { SectionLoader } from '../SectionLoader';
import { PropTypes } from 'prop-types';
import '../../App.scss';
import styles from './styles.module.scss';

const tableRowData = [
  { title: 'Accrual', getDataByKey: 'accrual' },
  { title: 'Used', getDataByKey: 'used' }
];

export const VacationReport = ({
  vacationData,
  selectedYear,
  loading,
  isSpecialPermissionUser
}) => {
  const { yearData, standingYears, employmentDate, terminationDate } = vacationData;

  return (
    <>
      <div>
        {vacationData && Object.keys(yearData).length !== 0 ? (
          <div className="vacation-and-sick-leave-section-wrapper">
            {isSpecialPermissionUser && <SectionLoader loading={loading} />}

            <VacationGeneralInformation
              standingYears={standingYears}
              employmentDate={employmentDate}
              terminationDate={terminationDate}
            />
            <VacationBalance vacationData={vacationData} />

            <VacationAndSickLeaveTable
              yearData={yearData}
              tableRowData={tableRowData}
              tableName="Vacation accrual/used, days"
              selectedYear={selectedYear}
            />
          </div>
        ) : (
          <div className={styles['vacation-and-sick-leave-section-notification']}>
            There is no data available
          </div>
        )}
      </div>
    </>
  );
};

VacationReport.propTypes = {
  isSpecialPermissionUser:  PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  selectedYear: PropTypes.number,
  vacationData: PropTypes.shape({
    employmentDate: PropTypes.string,
    firstReportedDate: PropTypes.string,
    terminationDate: PropTypes.string,
    standingYears: PropTypes.number.isRequired,
    transferredBalance: PropTypes.number.isRequired,
    minimumAllowedBalance: PropTypes.number.isRequired,
    currentVacationBalance: PropTypes.number.isRequired,
    expectedEoyVacationBalance: PropTypes.number.isRequired,
    yearData: PropTypes.shape({
      january: PropTypes.shape({
        accrual: PropTypes.number.isRequired,
        used: PropTypes.number,
        usedUnpaid: PropTypes.number
      }),
      february: PropTypes.shape({
        accrual: PropTypes.number.isRequired,
        used: PropTypes.number,
        usedUnpaid: PropTypes.number
      }),
      march: PropTypes.shape({
        accrual: PropTypes.number.isRequired,
        used: PropTypes.number,
        usedUnpaid: PropTypes.number
      }),
      april: PropTypes.shape({
        accrual: PropTypes.number.isRequired,
        used: PropTypes.number,
        usedUnpaid: PropTypes.number
      }),
      may: PropTypes.shape({
        accrual: PropTypes.number.isRequired,
        used: PropTypes.number,
        usedUnpaid: PropTypes.number
      }),
      june: PropTypes.shape({
        accrual: PropTypes.number.isRequired,
        used: PropTypes.number,
        usedUnpaid: PropTypes.number
      }),
      july: PropTypes.shape({
        accrual: PropTypes.number.isRequired,
        used: PropTypes.number,
        usedUnpaid: PropTypes.number
      }),
      august: PropTypes.shape({
        accrual: PropTypes.number.isRequired,
        used: PropTypes.number,
        usedUnpaid: PropTypes.number
      }),
      september: PropTypes.shape({
        accrual: PropTypes.number.isRequired,
        used: PropTypes.number,
        usedUnpaid: PropTypes.number
      }),
      october: PropTypes.shape({
        accrual: PropTypes.number.isRequired,
        used: PropTypes.number,
        usedUnpaid: PropTypes.number
      }),
      november: PropTypes.shape({
        accrual: PropTypes.number.isRequired,
        used: PropTypes.number,
        usedUnpaid: PropTypes.number
      }),
      december: PropTypes.shape({
        accrual: PropTypes.number.isRequired,
        used: PropTypes.number,
        usedUnpaid: PropTypes.number
      })
    })
  }).isRequired
};
