import { useMemo } from 'react';
import environment from '../../config';
import { useFetch } from '../../hooks';
import { formatData, getFormatedSelectedEmployee } from './utils';

export const useStatisticsData = (
  selectedYear,
  selectedManager,
  selectedProject,
  selectedEmployee
) => {
  const {
    data: dataFromServer,
    initialLoading,
    loading: dataFromServerLoading
  } = useFetch(`${environment.timetrackingBaseUrl}/v1/Vacation/All?year=${selectedYear}`);

  const { data: managers } = useFetch(`${environment.timetrackingBaseUrl}/v1/Vacation/Managers`);

  const { data: employeeByManager } = useFetch(
    `${environment.timetrackingBaseUrl}/v1/Vacation/All?year=${selectedYear}&managerId=${selectedManager[0]?.id}`,
    selectedManager.length === 1
  );

  const { data: projects } = useFetch(`${environment.apiEmployeeBaseUrl}/vacations/projects`);

  const { data: employeeByProject } = useFetch(
    `${environment.timetrackingBaseUrl}/v1/Vacation/All?year=${selectedYear}&projectId=${selectedProject[0]?.id}`,
    selectedProject.length === 1
  );

  const allFormatedData = useMemo(() => formatData(dataFromServer), [dataFromServer]);

  const formatedDataByManager = useMemo(() => formatData(employeeByManager), [employeeByManager]);

  const formatedDataByProject = useMemo(() => formatData(employeeByProject), [employeeByProject]);

  let formatedSelectedEmployee = useMemo(
    () => getFormatedSelectedEmployee(allFormatedData, selectedEmployee),
    [allFormatedData, selectedEmployee]
  );

  return {
    allFormatedData,
    initialLoading,
    dataFromServerLoading,
    managers,
    formatedDataByManager,
    projects,
    formatedDataByProject,
    formatedSelectedEmployee
  };
};
