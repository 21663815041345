import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { OidcProvider } from '@axa-fr/react-oidc';
import environment from './config';
import './App.scss';
import { AppRoutes } from './pages/AppRoutes';

const configuration = {
  client_id: 'leobit.vacation.web',
  redirect_uri: window.location.origin + '/authentication/callback',
  silent_redirect_uri: window.location.origin + '/authentication/silent-callback',
  scope: 'employee TimeTracking openid profile offline_access',
  authority: `${environment.issuer}`,
  service_worker_relative_url: '/OidcServiceWorker.js',
  service_worker_only: false
};

const App = () => (
  <OidcProvider
    configuration={configuration}
    loadingComponent={() => <></>}
    authenticatingComponent={() => <></>}
    callbackSuccessComponent={() => <></>}>
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  </OidcProvider>
);

export default App;
