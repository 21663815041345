import { useMemo } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import 'react-calendar-timeline/lib/Timeline.css';
import { Footer } from '../../components/Footer';
import { useOidc, useOidcUser, OidcUserStatus } from '@axa-fr/react-oidc';
import { EMPLOYEE_PERMISSION_LEVELS } from '../../common/const/generalConst';
import LbtNav from 'lbt-navigation';
import 'lbt-navigation/dist/index.css';
import styles from './styles.module.scss';
import environment from '../../config';

export const Home = () => {
  const { logout, isAuthenticated } = useOidc();
  const { oidcUser, oidcUserLoadingState } = useOidcUser();
  const navUrl = `${environment.issuer}/navigation?env=${environment.production ? 'prod' : 'test'}`;
  const navigate = useNavigate();

  const allTabs = useMemo(
    () => [
      {
        id: 'chart',
        lable: 'Chart',
        route: '/chart',
        command: () => navigate('/chart')
      },
      {
        id: 'statistics',
        lable: 'Statistics',
        route: '/statistics',
        command: () => navigate('/statistics')
      },
      {
        id: 'vacation-and-sick-leave',
        lable: 'Vacation & Sick leave',
        route: '/vacation-and-sick-leave',
        command: () => navigate('/vacation-and-sick-leave')
      }
    ],
    [navigate]
  );

  const standartUserTabs = useMemo(
    () => allTabs.slice(0, 1).concat(allTabs.slice(2, 3)),
    [allTabs]
  );

  const isSpecialPermissionUser =
    oidcUserLoadingState === OidcUserStatus.Loaded &&
    (oidcUser.EmployeeVacationAndSickLeave === EMPLOYEE_PERMISSION_LEVELS.viewAll ||
      oidcUser.EmployeeVacationAndSickLeave === EMPLOYEE_PERMISSION_LEVELS.viewAllAndEdit);

  const availableTabs = isSpecialPermissionUser ? allTabs : standartUserTabs;

  return (
    <LbtNav
      className={styles.container}
      title="Vacation"
      isAuthenticated={isAuthenticated}
      toggleSignOut={logout}
      redirectPathAfterSignOut="/"
      user={{ name: oidcUser?.name, picture: oidcUser?.picture }}
      sidebarLinksEndpoint={navUrl}
      tabs={oidcUserLoadingState === OidcUserStatus.Loaded && availableTabs}>
      <div className={styles['main-wrapper']}>
        <Outlet />
      </div>

      <Footer />
    </LbtNav>
  );
};
