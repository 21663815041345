import React from 'react';
import classNames from 'classnames';
import { PropTypes } from 'prop-types';
import styles from './styles.module.scss';

const monthNames = [
  'jan',
  'feb',
  'mar',
  'apr',
  'may',
  'jun',
  'jul',
  'aug',
  'sep',
  'oct',
  'nov',
  'dec'
];

export const TablePopUp = ({ modalInfo, setIsModalOpen }) => {
  const currentMonth = new Date().getMonth();

  return (
    <>
      <div className={styles['modal-block']}>
        <div className={styles['modal-block__title']}>
          <span className={styles['modal-block__name']}>{modalInfo.fullName}</span>

          <span className={styles['modal-block__details']}>Details</span>
        </div>

        <span className={styles['modal-block__cancel']} onClick={() => setIsModalOpen(false)}>
          <i className="pi pi-times"></i>
        </span>

        <div className={styles['modal-block__cells']}>
          {monthNames.map((name, index) => (
            <div
              className={classNames(styles['modal-block__cell'], {
                [styles['modal-block__cell--previous']]: currentMonth > index,
                [styles['modal-block__cell--active']]: currentMonth === index
              })}
              key={name}>
              <span className={styles['modal-block__month']}>{name}</span>

              <span className={styles['modal-block__number']}>{modalInfo[name]}</span>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

TablePopUp.propTypes = {
  modalInfo: PropTypes.shape({
    id: PropTypes.string.isRequired,
    fullName: PropTypes.string.isRequired,
    apr: PropTypes.string,
    aug: PropTypes.string,
    dec: PropTypes.string,
    feb: PropTypes.string,
    jan: PropTypes.string,
    jul: PropTypes.string,
    jun: PropTypes.string,
    mar: PropTypes.string,
    may: PropTypes.string,
    nov: PropTypes.string,
    oct: PropTypes.string,
    sep: PropTypes.string,
    totalAtThisYear: PropTypes.string,
    totalUsed: PropTypes.number,
    totalUsedPercent: PropTypes.string,
    totalUsedPercentPure: PropTypes.string,
    expectedEoyVacationBalance: PropTypes.number
  }).isRequired,
  setIsModalOpen: PropTypes.func.isRequired
};
