import { PropTypes } from 'prop-types';
import styles from './styles.module.scss';

export const Tooltip = ({ children, content }) => {
  return (
    <div className={styles['tooltip-container']}>
      {children}
      <div className={styles['tooltip-content']}>
        <span className={styles['tooltip-text']}>{content}</span>
        <div className={styles['tooltip-arrow']} />
      </div>
    </div>
  );
};

Tooltip.propTypes = {
  content: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};
