export const YEAR_START = new Date().getFullYear();
export const NUM_OF_YEARS = 1;
export const MONTH_NAMES = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];
export const MONTHS_PER_YEAR = 12;
export const QUARTERS_PER_YEAR = 4;
export const MONTHS_PER_QUARTER = 3;
export const NUM_OF_MONTHS = NUM_OF_YEARS * MONTHS_PER_YEAR;
export const MAX_TRACK_START_GAP = 4;
export const MAX_ELEMENT_GAP = 8;
export const MAX_MONTH_SPAN = 8;
export const MIN_MONTH_SPAN = 2;
export const NUM_OF_TRACKS = 20;
export const MAX_NUM_OF_SUBTRACKS = 5;

export const start = new Date(`${YEAR_START}`);
export const end = new Date(`${YEAR_START + NUM_OF_YEARS}`, 2);
export const MIN_ZOOM = 20;
export const MAX_ZOOM = 50;

export const eventTypes = [
  { id: 'sickness', value: 'sickness', label: '🤒 Sickness' },
  { id: 'dayOff', value: 'dayOff', label: '🌴 Day off' }
];
