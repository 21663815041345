import { Route, Routes } from 'react-router-dom';
import { SecureChart } from '../components/Chart';
import { SecureStatistics } from '../components/Statistics';
import { SecureVacationAndSickLeave } from '../components/VacationAndSickLeave';
import { Home } from '../pages/Home';

export const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<Home />}>
      <Route index element={<SecureChart />} />
      <Route path="chart" element={<SecureChart />} />
      <Route path="statistics" element={<SecureStatistics />} />
      <Route path="vacation-and-sick-leave" element={<SecureVacationAndSickLeave />} />
    </Route>
  </Routes>
);
