import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

const StatisticsTable = ({ headerGroups, rows, prepareRow, getTableProps, getTableBodyProps }) => (
  <table {...getTableProps()} className={styles.table}>
    <thead>
      {headerGroups.map((headerGroup) => {
        const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps();
        return (
          <tr key={key} {...restHeaderGroupProps}>
            {headerGroup.headers.map((column) => {
              const { key, ...restColumn } = column.getHeaderProps(column.getSortByToggleProps());
              return (
                <th
                  key={key}
                  {...restColumn}
                  className={classNames(styles['table-title'], {
                    [styles['table-title--active']]: column.isSorted
                  })}>
                  {column.render('Header')}
                  <span className={styles['table-title__icon-arrow']}>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <i
                          className="pi pi-arrow-down table-title__icon-arrow"
                          style={{ fontWeight: 'bold' }}></i>
                      ) : (
                        <i className="fas pi pi-arrow-up" style={{ fontWeight: 'bold' }}></i>
                      )
                    ) : (
                      ''
                    )}
                  </span>
                </th>
              );
            })}
          </tr>
        );
      })}
    </thead>

    <tbody {...getTableBodyProps()}>
      {rows.map((row) => {
        prepareRow(row);
        const { key, ...restRowProps } = row.getRowProps();
        return (
          <tr key={key} {...restRowProps} className={styles['table-row']}>
            {row.cells.map((cell) => {
              const { key, ...restCellProps } = cell.getCellProps();
              return (
                <td key={key} {...restCellProps}>
                  {cell.render('Cell')}
                </td>
              );
            })}
          </tr>
        );
      })}
    </tbody>
  </table>
);

export default StatisticsTable;
