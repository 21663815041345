import React from 'react';
import { version } from '../../../package.json';
import styles from './styles.module.scss';

export const Footer = () => {
  return (
    <div className={styles.footer}>
      Leobit Vacation application. Copyright © by Leobit, LLC. {new Date().getFullYear()}y.{' '}
      {version}
    </div>
  );
};
