import { namesOptions, projectNamesOptions } from '../Statistics/utils';
import { MONTHS_PER_YEAR, start } from './constants';

export const addMonthsToYearAsDate = (year, monthsToAdd) => {
  const r = addMonthsToYear(year, monthsToAdd);
  return new Date(`${r.year}-${r.month}`);
};

export const addMonthsToYear = (year, monthsToAdd) => {
  let y = year;
  let m = monthsToAdd;
  while (m >= MONTHS_PER_YEAR) {
    m -= MONTHS_PER_YEAR;
    y += 1;
  }
  return { year: y, month: m + 1 };
};

export const setUpHolidaysClasses = (holidays, isLoading) => {
  const addDay = (date, daysCount) => {
    let newDate = new Date(date);
    newDate.setDate(date.getDate() + daysCount);

    return newDate;
  };

  let gridColumnsHeaders =
    !isLoading &&
    (window.document
      ?.getElementsByClassName('rt-timebar__row')[1]
      ?.getElementsByClassName('rt-timebar__cell') ??
      []);

  let gridColumns = window.document?.getElementsByClassName('rt-grid__cell');

  for (var i = 0; i < gridColumns.length; i++) {
    let dayColumn = addDay(start, i);

    if (
      dayColumn.getDay() === 6 ||
      dayColumn.getDay() === 0 ||
      holidays.findIndex(
        (h) =>
          h.date.getFullYear() === dayColumn.getFullYear() &&
          h.date.getMonth() === dayColumn.getMonth() &&
          h.date.getDate() === dayColumn.getDate()
      ) !== -1
    ) {
      const column = gridColumns[i];
      const columnheader = gridColumnsHeaders[i];
      column.classList.add('holiday');
      columnheader.classList.add('holiday');
    }
  }
};

export const processProjectsFilters = (projectsData, queryParams) => {
  const projectIds = queryParams.get('projects')?.split(',') || [];
  const parsedProjectIds = projectIds.map((id) => Number(id));

  const projects = projectNamesOptions(projectsData);

  let displayedProjects;
  let projectsIdForRequest;

  if (projectIds.length === 0) {
    displayedProjects = projects;
    projectsIdForRequest = projects.map((p) => p.id);
  } else {
    displayedProjects = projects.filter((p) => parsedProjectIds.includes(p.id));
    projectsIdForRequest = parsedProjectIds;
  }

  return { projects, displayedProjects, projectsIdForRequest };
};

export const processEmployeesFilters = (employeesData, queryParams) => {
  const employeeIds = queryParams.get('employees')?.split(',') || [];

  const employees = namesOptions(employeesData);

  let displayedEmployees;
  let employeesIdForRequest;

  if (employeeIds.length === 0) {
    displayedEmployees = employees;
    employeesIdForRequest = employees.map((p) => p.id);
  } else {
    displayedEmployees = employees.filter((p) => employeeIds.includes(p.id));
    employeesIdForRequest = employeeIds;
  }

  return { employees, displayedEmployees, employeesIdForRequest };
};
