import moment from 'moment';

export const months = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december'
];

export const findTotalInRow = (data, key) => {
  const total = Object.values(data).reduce((sum, month) => {
    return sum + month[key];
  }, 0);

  return total.toFixed(2);
};

export const formatNumber = (number) => number.toFixed(2);

export const getSumOfDataTillToday = (yearData, key, includeCurrentMonth = false) => {
  const currentDate = moment();
  const currentMonth = currentDate.month();

  let sum = 0;

  Object.keys(yearData).forEach((monthName, index) => {
    if (includeCurrentMonth ? index <= currentMonth : index < currentMonth) {
      const searchKey = yearData[monthName][key];

      sum += searchKey ? searchKey : 0;
    }
  });

  return sum.toFixed(2);
};

export const calculatePercentage = (part, total) => {
  if (total === 0) {
    return 0;
  }

  return Math.round((part / total) * 100);
};
