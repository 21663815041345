import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { TablePopUp } from '../TablePopUp';

import styles from './styles.module.scss';

export const DetailsButton = ({ dataForModal }) => {
  const ref = React.useRef();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalInfo, setModalInfo] = useState();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (isModalOpen && ref.current && !ref.current.contains(e.target)) {
        setIsModalOpen(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isModalOpen]);

  const onClickDetails = (isOpenModal, data) => {
    setIsModalOpen(isOpenModal);
    setModalInfo(data);
  };

  return (
    <>
      <div className={styles['details']} ref={ref}>
        <div className={styles['modal']}>
          {isModalOpen && <TablePopUp modalInfo={modalInfo} setIsModalOpen={setIsModalOpen} />}
        </div>
        <button
          onClick={() => onClickDetails(!isModalOpen, dataForModal)}
          className={styles['details-button']}>
          Details
        </button>
      </div>
    </>
  );
};

DetailsButton.propTypes = {
  dataForModal: PropTypes.shape({
    id: PropTypes.string.isRequired,
    fullName: PropTypes.string.isRequired,
    apr: PropTypes.string,
    aug: PropTypes.string,
    dec: PropTypes.string,
    feb: PropTypes.string,
    jan: PropTypes.string,
    jul: PropTypes.string,
    jun: PropTypes.string,
    mar: PropTypes.string,
    may: PropTypes.string,
    nov: PropTypes.string,
    oct: PropTypes.string,
    sep: PropTypes.string,
    totalAtThisYear: PropTypes.string,
    totalUsed: PropTypes.number,
    totalUsedPercent: PropTypes.string,
    totalUsedPercentPure: PropTypes.string,
    expectedEoyVacationBalance: PropTypes.number
  })
};
