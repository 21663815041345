import { PropTypes } from 'prop-types';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { Tooltip } from '../Tooltip';

const questionMarkIcon = `${process.env.PUBLIC_URL}/assets/question_mark.png`;

export const VacationBalanceItem = ({ data, title, icon, isCurrent, creditBallance }) => {
  const absCreditBallance = Math.abs(creditBallance).toFixed(2);

  return (
    <div
      className={classNames(styles['vacation-balance-item'], {
        [styles['vacation-balance-item--current']]: isCurrent
      })}>
      <img src={icon} alt="icon" />

      <div className={styles['vacation-balance-item__content']}>
        <div className={styles['vacation-balance-item__info']}>
          {data}
          {creditBallance <= 0 && (
            <div
              className={classNames({
                [styles['vacation-balance-item__credit']]: absCreditBallance
              })}>
              <div className={styles['vacation-balance-item__credit-number']}>
                Cred.&nbsp;<b>{absCreditBallance}</b>
              </div>

              <Tooltip
                content="This credit allows you to take additional vacation days beyond your accrued balance if
          needed">
                <img src={questionMarkIcon} alt="question_mark_tooltip" />
              </Tooltip>
            </div>
          )}
        </div>
        <div className={styles['vacation-balance-item__title']}>{title}</div>
      </div>
    </div>
  );
};

VacationBalanceItem.propTypes = {
  data: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  isCurrent: PropTypes.bool,
  creditBallance: PropTypes.number
};
