import { useEffect, useState } from 'react';

export const useFetch = (url, loadData = true) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [initialLoading, setInitialLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      setLoading(true);

      try {
        const response = await fetch(url);
        const responseData = await response.json();

        if (isMounted) {
          setData(responseData);
        }
      } catch (e) {
        setError(e);
        console.log('Error:', e);
      } finally {
        setLoading(false);
        setInitialLoading(false);
      }
    };

    if (loadData) {
      fetchData();
    }

    return () => {
      isMounted = false;
    };
  }, [url, loadData]);

  return { data, initialLoading, loading, error };
};
