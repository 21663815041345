import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import colors from '../../styles/colors.module.scss';
import { PropTypes } from 'prop-types';

export const CircleProgressbar = ({ percentage, styles }) => (
  <div style={styles}>
    <CircularProgressbar
      value={percentage}
      strokeWidth={11}
      styles={buildStyles({
        pathColor: colors.mainColor,
        trailColor: colors.mainTransparent
      })}
    />
  </div>
);

CircleProgressbar.propTypes = {
  percentage: PropTypes.number.isRequired,
  styles: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number
  })
};
