import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router';

import { start, end, MIN_ZOOM, MAX_ZOOM, eventTypes } from './constants';
import { buildTimebar } from './builders';
import Timeline from 'react-timelines';
import 'react-timelines/lib/css/style.css';
import { OidcSecure, useOidcFetch } from '@axa-fr/react-oidc';
import classNames from 'classnames';
import environment from '../../config';
import { useFetch } from '../../hooks';
import { useFetchSchedules } from './useFetchSchedules';
import { useFilterSchedules } from './useFilterSchedule';
import { SelectFilter } from '../SelectFilter';
import { PageLoader } from '../PageLoader';
import { processEmployeesFilters, processProjectsFilters, setUpHolidaysClasses } from './utils';
import { updateUrlParams } from '../../common/utils/queryUtils';
import styles from './styles.module.scss';

const Chart = () => {
  const [isLoading, setLoading] = useState(true);
  const [isChecked, setIsChecked] = useState(false);
  const [idsForRequest, setIdsForRequest] = useState(null);

  const [holidays, setHolidays] = useState([]);
  const [projects, setProjects] = useState([]);
  const [employees, setEmployees] = useState([]);

  const [selectedProjectsOptions, setSelectedProjects] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [selectedEventTypesOptions, setSelectedEventTypes] = useState([]);

  const { data: holidaysData } = useFetch(`${environment.apiEmployeeBaseUrl}/vacations/holidays`);
  const { data: projectsData } = useFetch(`${environment.apiEmployeeBaseUrl}/vacations/projects`);
  const { data: employeesData } = useFetch(`${environment.apiEmployeeBaseUrl}/vacations/employees`);

  const { tracks } = useFetchSchedules(
    idsForRequest?.projectIds,
    idsForRequest?.userIds,
    !!idsForRequest
  );

  const { schedules } = useFilterSchedules(tracks, selectedEventTypesOptions, isChecked);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (holidaysData.length > 0 && projectsData.length > 0 && projectsData.length > 0) {
      const queryParams = new URLSearchParams(location.search);

      const { projects, displayedProjects, projectsIdForRequest } = processProjectsFilters(
        projectsData,
        queryParams
      );
      setProjects(projects);
      setSelectedProjects(displayedProjects);

      const { employees, displayedEmployees, employeesIdForRequest } = processEmployeesFilters(
        employeesData,
        queryParams
      );
      setEmployees(employees);
      setSelectedEmployees(displayedEmployees);

      const eventTypeIds = queryParams.get('eventTypes')?.split(',') || [];
      const selectedEventTypes = eventTypeIds.includes('all')
        ? eventTypes
        : eventTypes.filter((e) => eventTypeIds.includes(e.id));

      setSelectedEventTypes(selectedEventTypes.length === 0 ? eventTypes : selectedEventTypes);

      const isChecked = queryParams.get('ongoing') === 'true';

      setIsChecked(isChecked);

      setLoading(false);

      const holidays = holidaysData.map(({ date, name }) => ({
        date: new Date(date),
        id: name
      }));
      setHolidays(holidays);

      if (projectsIdForRequest.length > 0 || employeesIdForRequest.length > 0) {
        setIdsForRequest({
          projectIds: projectsIdForRequest,
          userIds: employeesIdForRequest
        });
      }
    }
  }, [location.search, holidaysData, projectsData, employeesData]);

  const getIdsForUseFetchSchedules = (selectedProjectsOptions, selectedEmployees, employees) => {
    let projectIds = selectedProjectsOptions.map((p) => p.id);
    if (projectIds.length === 0) projectIds = [-1];

    let userIds = selectedEmployees.map((u) => u.id);
    if (userIds.length === employees.length) userIds = [];

    setIdsForRequest({ projectIds, userIds });
  };

  const onApplySelectedProject = (selectedValues) => {
    setSelectedProjects(selectedValues);
    getIdsForUseFetchSchedules(selectedValues, selectedEmployees, employees);
    updateUrlParams(
      [{ paramName: 'projects', paramValue: selectedValues, allOptions: projects }],
      navigate
    );
  };

  const onApplySelectedEmployees = (selectedValues) => {
    setSelectedEmployees(selectedValues);
    getIdsForUseFetchSchedules(selectedProjectsOptions, selectedValues, employees);
    updateUrlParams(
      [{ paramName: 'employees', paramValue: selectedValues, allOptions: employees }],
      navigate
    );
  };

  const onApplySelectedEventType = (selectedValues) => {
    setSelectedEventTypes(selectedValues);
    getIdsForUseFetchSchedules(selectedProjectsOptions, selectedEmployees, employees);
    updateUrlParams(
      [{ paramName: 'eventTypes', paramValue: selectedValues, allOptions: eventTypes }],
      navigate
    );
  };

  const onApplyOngoing = () => {
    setIsChecked(!isChecked);
    updateUrlParams([{ paramName: 'ongoing', paramValue: !isChecked, isBoolean: true }], navigate);
  };

  setUpHolidaysClasses(holidays, isLoading);

  let zoom = 35;

  const handleZoomIn = () => {
    zoom = Math.min(zoom + 1, MAX_ZOOM);
  };

  const handleZoomOut = () => {
    zoom = Math.max(zoom - 1, MIN_ZOOM);
  };

  const timebar = buildTimebar();

  return (
    <>
      {isLoading ? (
        <PageLoader />
      ) : (
        <>
          <div>
            <div className={styles['filters']}>
              {projects.length > 0 && (
                <div className={styles['filter-item']}>
                  <div className={styles['filter-title']}>Project</div>

                  <SelectFilter
                    options={projects}
                    selectedItems={selectedProjectsOptions}
                    onHandleChange={onApplySelectedProject}
                    hasSelectAll={true}
                    className={styles.multiselect}
                  />
                </div>
              )}

              {employees.length > 0 && (
                <div className={styles['filter-item']} tabIndex="0">
                  <div className={styles['filter-title']}>Employee Name</div>

                  <SelectFilter
                    options={employees}
                    selectedItems={selectedEmployees}
                    onHandleChange={onApplySelectedEmployees}
                    labelledBy="Select"
                    hasSelectAll={true}
                    className={styles.multiselect}
                  />
                </div>
              )}

              {eventTypes.length > 0 && (
                <div className={styles['filter-item']}>
                  <div className={styles['event-type-title']}>
                    <div className={styles['filter-title']}>Operational Event Type</div>

                    <div>
                      <input
                        type="checkbox"
                        className={styles['ongoing']}
                        id="ongoing"
                        name="ongoing"
                        checked={isChecked}
                        onChange={onApplyOngoing}
                      />
                      <label htmlFor="ongoing"> Ongoing</label>
                    </div>
                  </div>

                  <div className={styles['event-type-selector']}>
                    <div
                      className={classNames(styles['event-type-item'], {
                        [styles['event-type-item--active']]: selectedEventTypesOptions.length === 2
                      })}
                      onClick={() => onApplySelectedEventType(eventTypes)}>
                      All
                    </div>

                    {eventTypes.map((type) => (
                      <div
                        className={classNames(styles['event-type-item'], {
                          [styles['event-type-item--active']]:
                            selectedEventTypesOptions.length !== 2 &&
                            selectedEventTypesOptions[0].id === type.id
                        })}
                        key={type.id}
                        onClick={() => onApplySelectedEventType([type])}>
                        {type.label}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className={styles['chart-wrapper']}>
            <div className={styles.chart}>
              <Timeline
                scale={{
                  start,
                  end,
                  zoom,
                  zoomMin: MIN_ZOOM,
                  zoomMax: MAX_ZOOM
                }}
                isOpen={true}
                zoomIn={handleZoomIn}
                zoomOut={handleZoomOut}
                timebar={timebar}
                tracks={schedules}
                now={new Date()}
                enableSticky
                scrollToNow
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export const SecureChart = () => {
  const { fetch } = useOidcFetch();

  return (
    <OidcSecure>
      <Chart fetch={fetch} />
    </OidcSecure>
  );
};
