import { BarLoader } from 'react-spinners';
import { PropTypes } from 'prop-types';
import colors from '../../styles/colors.module.scss';

export const SectionLoader = ({ loading }) => (
  <BarLoader
    color={colors.mainColor}
    height={2}
    loading={loading}
    cssOverride={{
      position: 'relative',
      bottom: '24px',
      width: 'calc(100% + 44px)',
      marginLeft: '-22px',
      gridColumn: '1 / 5',
      gridRow: '1 / 2'
    }}
  />
);

SectionLoader.propTypes = {
  loading: PropTypes.bool.isRequired
};
