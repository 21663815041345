import { YEAR_START, NUM_OF_YEARS, MONTH_NAMES, MONTHS_PER_YEAR } from './constants';

import { addMonthsToYearAsDate } from './utils';

export const buildMonthCells = () => {
  const monthCells = [];

  for (let i = 0; i < MONTHS_PER_YEAR * NUM_OF_YEARS + 2; i += 1) {
    const startMonth = i;
    const start = addMonthsToYearAsDate(YEAR_START, startMonth);
    const end = addMonthsToYearAsDate(YEAR_START, startMonth + 1);

    monthCells.push({
      id: `m${startMonth}`,
      title: i < 12 ? MONTH_NAMES[i % 12] : i === 12 ? MONTH_NAMES[0] : MONTH_NAMES[1],
      start,
      end
    });
  }

  return monthCells;
};

export const buildDayCells = () => {
  const dayCells = [];
  const startDate = new Date(YEAR_START, 0, 1);
  const numberOfDays = 366 + 31 + 29; // the timeline has to display 1 year and 2 months of the next year

  for (let i = 0; i < numberOfDays; i += 1) {
    const start = new Date(startDate);
    start.setDate(startDate.getDate() + i);

    const end = new Date(startDate);
    end.setDate(startDate.getDate() + i + 1);

    dayCells.push({
      id: `d${i}`,
      title: start.getDate().toString(),
      start,
      end
    });
  }

  return dayCells;
};

export const buildTimebar = () => [
  {
    id: 'months',
    title: 'Months',
    cells: buildMonthCells(),
    style: {}
  },
  {
    id: 'days',
    title: 'Days',
    cells: buildDayCells(),
    useAsGrid: true,
    style: {}
  }
];

//   export const buildElement = ({ trackId, start, end, i }) => {
//     const bgColor = nextColor()
//     // const color = colourIsLight(...hexToRgb(bgColor)) ? '#000000' : '#ffffff'
//     return {
//       id: `t-${trackId}-el-${i}`,
//       title: randomTitle(),
//       start,
//       end,
//       style: {
//         backgroundColor: `#${bgColor}`,
//         color,
//         borderRadius: '4px',
//         boxShadow: '1px 1px 0px rgba(0, 0, 0, 0.25)',
//         textTransform: 'capitalize',
//       },
//     }
//   }

//   export const buildTrackStartGap = () => Math.floor(Math.random() * MAX_TRACK_START_GAP)
//   export const buildElementGap = () => Math.floor(Math.random() * MAX_ELEMENT_GAP)

//   export const buildElements = trackId => {
//     const v = []
//     let i = 1
//     let month = buildTrackStartGap()

//     while (month < NUM_OF_MONTHS) {
//       let monthSpan = Math.floor(Math.random() * (MAX_MONTH_SPAN - (MIN_MONTH_SPAN - 1))) + MIN_MONTH_SPAN

//       if (month + monthSpan > NUM_OF_MONTHS) {
//         monthSpan = NUM_OF_MONTHS - month
//       }

//       const start = addMonthsToYearAsDate(YEAR_START, month)
//       const end = addMonthsToYearAsDate(YEAR_START, month + monthSpan)
//       v.push(
//         buildElement({
//           trackId,
//           start,
//           end,
//           i,
//         })
//       )
//       const gap = buildElementGap()
//       month += monthSpan + gap
//       i += 1
//     }

//     return v
//   }

//   export const buildSubtrack = (trackId, subtrackId) => ({
//     id: `track-${trackId}-${subtrackId}`,
//     title: `Subtrack ${subtrackId}`,
//     elements: buildElements(subtrackId),
//   })

//   export const buildTrack = trackId => {
//     const tracks = fill(Math.floor(Math.random() * MAX_NUM_OF_SUBTRACKS) + 1).map(i => buildSubtrack(trackId, i + 1))
//     return {
//       id: `track-${trackId}`,
//       title: `Track ${trackId}`,
//       elements: buildElements(trackId),
//       tracks,
//       // hasButton: true,
//       // link: 'www.google.com',
//       isOpen: false,
//     }
//   }
