/* eslint-disable no-undef */

const environment = {
  production: process.env.REACT_APP_IS_PRODUCTION === 'TRUE',
  apiEmployeeBaseUrl: process.env.REACT_APP_API_EMPLOYEE_BASE_URL,
  timetrackingBaseUrl: process.env.REACT_APP_TIMETRACKING_BASE_URL,
  issuer: process.env.REACT_APP_ISSUER,
  rootUrl: process.env.REACT_APP_ROOT_URL
};

export default environment;
