import environment from '../../config';
import { useFetch } from '../../hooks';

export const useVacationAndSickLeaveData = (
  selectedYear,
  selectedEmployee,
  isStandartPermissionUser,
  isSpecialPermissionUser
) => {
  const hasSpecialPermissionToDownloadData =
    isSpecialPermissionUser && selectedEmployee.length === 1;

  const { data: userVacationData, initialLoading: standartUserVacationInitialLoading } = useFetch(
    `${environment.timetrackingBaseUrl}/v1/Vacation?year=${selectedYear}`,
    isStandartPermissionUser
  );

  const { data: userSickLeaveData, initialLoading: standartUserSickLeaveInitialLoading } = useFetch(
    `${environment.timetrackingBaseUrl}/v1/SickLeave?year=${selectedYear}`,
    isStandartPermissionUser
  );

  const { data: employeesData } = useFetch(
    `${environment.apiEmployeeBaseUrl}/vacations/employees`,
    hasSpecialPermissionToDownloadData
  );

  const {
    data: checkedEmployeeVacationData,
    initialLoading: checkedEmployeeVacationInitialLoading,
    loading: checkedEmployeeVacationLoading
  } = useFetch(
    `${environment.timetrackingBaseUrl}/v1/Vacation/Employee?employeeId=${selectedEmployee[0]?.id}&year=${selectedYear}`,
    hasSpecialPermissionToDownloadData
  );

  const {
    data: checkedEmployeeSickLeaveData,
    initialLoading: checkedEmployeeSickLeaveInitialLoading,
    loading: checkedEmployeeSickLeaveLoading
  } = useFetch(
    `${environment.timetrackingBaseUrl}/v1/SickLeave/Employee?employeeId=${selectedEmployee[0]?.id}&year=${selectedYear}`,
    hasSpecialPermissionToDownloadData
  );

  // set the value of initial loading depending on the data fetched
  const standartUserInitialLoading =
    standartUserVacationInitialLoading || standartUserSickLeaveInitialLoading;

  const checkedEmployeeInitialLoading =
    checkedEmployeeVacationInitialLoading || checkedEmployeeSickLeaveInitialLoading;

  const pageInitialLoading = hasSpecialPermissionToDownloadData
    ? checkedEmployeeInitialLoading
    : standartUserInitialLoading;

  // set the value of data which should be displayed depending on permissions
  const filteredVacationData = hasSpecialPermissionToDownloadData
    ? checkedEmployeeVacationData
    : userVacationData;

  const filteredSickLeaveData = hasSpecialPermissionToDownloadData
    ? checkedEmployeeSickLeaveData
    : userSickLeaveData;

  return {
    pageInitialLoading,
    employeesData,
    filteredVacationData,
    filteredSickLeaveData,
    checkedEmployeeVacationLoading,
    checkedEmployeeSickLeaveLoading
  };
};
