import React from 'react';
import { MultiSelect } from 'react-multi-select-component';
import { PropTypes } from 'prop-types';
import styles from './styles.module.scss';
import classNames from 'classnames';

const defaultSelectedValues = {
  allItemsAreSelected: 'Selected all',
  selectSomeItems: 'Select'
};

export const SelectFilter = ({
  options,
  onHandleChange,
  selectedItems,
  hasSelectAll = true,
  DefaultItemRenderer,
  additionalClassName = false
}) => {
  const handleOnChange = (checkedOptions) => {
    onHandleChange(checkedOptions);
  };

  const filterOptions = (options, filter) => {
    if (!filter) {
      return options;
    }

    const re = new RegExp(filter, 'i');

    return options.filter(({ value }) => value && value.match(re));
  };

  return (
    <MultiSelect
      options={options}
      value={selectedItems}
      onChange={handleOnChange}
      filterOptions={filterOptions}
      labelledBy="Select"
      className={classNames(styles.multiselect, {
        [styles['multiselect--grey']]: additionalClassName
      })}
      hasSelectAll={hasSelectAll}
      ItemRenderer={DefaultItemRenderer}
      overrideStrings={defaultSelectedValues}
    />
  );
};

SelectFilter.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    }).isRequired
  ).isRequired,
  onHandleChange: PropTypes.func,
  selectedItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    }).isRequired
  ).isRequired,
  hasSelectAll: PropTypes.bool,
  DefaultItemRenderer: PropTypes.any,
  additionalClassName: PropTypes.bool
};
