import { OidcSecure, OidcUserStatus, useOidcFetch, useOidcUser } from '@axa-fr/react-oidc';
import React, { useState, useEffect, useMemo } from 'react';
import { useTable, useSortBy, useFilters, useFlexLayout } from 'react-table';
import classNames from 'classnames';
import { YearProgress } from '../YearProgress';
import { CsvGenerator } from '../CsvGenerator';
import { YearSelector } from '../YearSelector';
import { PageLoader } from '../PageLoader';
import 'primeicons/primeicons.css';
import { statisticsColumns } from './constants';
import { useStatisticsData } from './useStatisticsData';
import { namesOptions, projectNamesOptions } from './utils';
import { BarLoader } from 'react-spinners';
import { SingleSelectOption } from '../SingleSelectOption';
import { SelectFilter } from '../SelectFilter';
import { useLocation, useNavigate } from 'react-router-dom';
import { processIdsFromQueryParam, updateUrlParams } from '../../common/utils/queryUtils';
import StatisticsTable from '../StatisticsTable/StatisticsTable';
import { handleMultipleSelect } from '../../common/utils/generalUtils';
import { EMPLOYEE_PERMISSION_LEVELS } from '../../common/const/generalConst';
import styles from './styles.module.scss';
import colors from '../../styles/colors.module.scss';

const Statistics = () => {
  const navigate = useNavigate();
  const location = useLocation();

  let [selectedManager, setSelectedManager] = useState([]);
  let [selectedProject, setSelectedProject] = useState([]);

  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedEmployee, setSelectedEmployee] = useState([]);
  const [selectedEmployeeByManager, setSelectedEmployeeByManager] = useState([]);
  const [selectedEmployeeByProject, setSelectedEmployeeByProject] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  // transform multiple select into single select
  handleMultipleSelect(selectedManager, setSelectedManager);
  handleMultipleSelect(selectedProject, setSelectedProject);

  const {
    allFormatedData,
    initialLoading,
    dataFromServerLoading,
    managers,
    projects,
    formatedDataByManager,
    formatedDataByProject,
    formatedSelectedEmployee
  } = useStatisticsData(selectedYear, selectedManager, selectedProject, selectedEmployee);

  const filteredEmployeeOptions =
    selectedManager.length === 1
      ? selectedEmployeeByManager
      : selectedProject.length === 1
      ? selectedEmployeeByProject
      : allFormatedData;

  const projectFilterOptions = useMemo(() => {
    return projectNamesOptions(projects);
  }, [projects]);

  const managerFilterOptions = useMemo(() => {
    return namesOptions(managers);
  }, [managers]);

  const employeeFilterOptions = useMemo(() => {
    return namesOptions(filteredEmployeeOptions);
  }, [filteredEmployeeOptions]);

  const isFilterUnused = useMemo(() => {
    return filteredData.length === allFormatedData.length || filteredData.length === 0;
  }, [filteredData, allFormatedData]);

  const [selectedOverallTab, setSelectedOverallTab] = useState(isFilterUnused);

  const selectedDataForYearProgress = selectedOverallTab ? allFormatedData : filteredData;

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const displayedProject = processIdsFromQueryParam(queryParams, 'project', projectFilterOptions);
    setSelectedProject(displayedProject);

    const displayedManager = processIdsFromQueryParam(queryParams, 'manager', managerFilterOptions);
    setSelectedManager(displayedManager);

    const displayedEmployees = processIdsFromQueryParam(
      queryParams,
      'employees',
      employeeFilterOptions
    );
    setSelectedEmployee(!!displayedEmployees.length ? displayedEmployees : employeeFilterOptions);

    const selectedOverallTabFromUrl = processIdsFromQueryParam(queryParams, 'overallTab');
    const isDisplayedOverallTab =
      selectedOverallTabFromUrl === null || selectedOverallTabFromUrl === 'true';
    setSelectedOverallTab(isDisplayedOverallTab);

    if (!selectedOverallTabFromUrl) {
      setSelectedOverallTab(isFilterUnused);
    }

    const selectedYearFromUrl = processIdsFromQueryParam(queryParams, 'year');
    const displayedSelectedYear =
      selectedYearFromUrl === null ? new Date().getFullYear() : +selectedYearFromUrl;
    setSelectedYear(displayedSelectedYear);
  }, [
    location.search,
    projectFilterOptions,
    managerFilterOptions,
    employeeFilterOptions,
    isFilterUnused
  ]);

  const onOverallTabChanged = () => {
    setSelectedOverallTab(true);
    updateUrlParams([{ paramName: 'overallTab', paramValue: true, isBoolean: true }], navigate);
  };

  const onFilteredTabChanged = () => {
    setSelectedOverallTab(false);
    updateUrlParams([{ paramName: 'overallTab', paramValue: false, isBoolean: true }], navigate);
  };

  const onYearChanged = (year) => {
    setSelectedYear(year);
    setSelectedEmployee([]);
    updateUrlParams([{ paramName: 'year', paramValue: year }], navigate);
  };

  const onEmployeeChanged = (employees) => {
    setSelectedEmployee(employees);
    updateUrlParams(
      [
        {
          paramName: 'employees',
          paramValue: employees,
          allOptions: employeeFilterOptions
        }
      ],
      navigate
    );
  };

  const onManagerChanged = (manager) => {
    setSelectedManager(manager);
    setSelectedProject([]);
    setSelectedEmployee([]);
    updateUrlParams(
      [
        {
          paramName: 'manager',
          paramValue: manager.length > 1 ? manager.slice(1) : manager,
          allOptions: managerFilterOptions
        },
        {
          paramName: 'project',
          paramValue: [],
          allOptions: projects
        },
        { paramName: 'overallTab', paramValue: [], isBoolean: true },
        {
          paramName: 'employees',
          paramValue: employeeFilterOptions,
          allOptions: employeeFilterOptions
        }
      ],
      navigate
    );
  };

  const onProjectChanged = (project) => {
    setSelectedProject(project);
    setSelectedManager([]);
    setSelectedEmployee([]);
    updateUrlParams(
      [
        {
          paramName: 'project',
          paramValue: project.length > 1 ? project.slice(1) : project,
          allOptions: projects
        },
        {
          paramName: 'manager',
          paramValue: [],
          allOptions: managerFilterOptions
        },
        { paramName: 'overallTab', paramValue: [], isBoolean: true },
        {
          paramName: 'employees',
          paramValue: employeeFilterOptions,
          allOptions: employeeFilterOptions
        }
      ],
      navigate
    );
  };

  const columns = React.useMemo(() => statisticsColumns, []);

  const defaultColumn = React.useMemo(
    () => ({
      // When using the useFlexLayout:
      minWidth: 20, // minWidth is only used as a limit for resizing
      width: 70, // width is used for both the flex-basis and flex-grow
      maxWidth: 200 // maxWidth is only used as a limit for resizing
    }),
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, setAllFilters } =
    useTable({ columns, data: filteredData, defaultColumn }, useFilters, useSortBy, useFlexLayout);

  useEffect(() => {
    const setAllFiltersOptions = (data) => {
      const checkedNamesArr = data.map((option) => option.fullName);

      setAllFilters([{ id: 'fullName', value: checkedNamesArr }]);
      setFilteredData(data);
    };

    switch (true) {
      case selectedManager.length === 1 && selectedEmployee.length > 0:
      case selectedProject.length === 1 && selectedEmployee.length > 0:
        setAllFiltersOptions(formatedSelectedEmployee);
        break;

      case selectedManager.length === 1:
        setSelectedEmployeeByManager(formatedDataByManager);
        setAllFiltersOptions(formatedDataByManager);
        break;

      case selectedProject.length === 1:
        setSelectedEmployeeByProject(formatedDataByProject);
        setAllFiltersOptions(formatedDataByProject);
        break;

      default:
        setAllFiltersOptions(formatedSelectedEmployee);
        break;
    }
  }, [
    selectedManager,
    selectedProject,
    formatedDataByManager,
    formatedDataByProject,
    formatedSelectedEmployee,
    setAllFilters,
    selectedEmployee.length
  ]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const ids = queryParams.get('employees')?.split(',') || [];

    if (selectedManager.length === 1 && ids.length === 0) {
      setSelectedEmployeeByManager(formatedDataByManager);
      setSelectedEmployee(namesOptions(formatedDataByManager));
    } else if (selectedProject.length === 1 && ids.length === 0) {
      setSelectedEmployeeByProject(formatedDataByProject);
      setSelectedEmployee(namesOptions(formatedDataByProject));
    }
  }, [
    selectedManager,
    selectedProject,
    formatedDataByManager,
    formatedDataByProject,
    location.search
  ]);

  return (
    <OidcSecure>
      <div className={styles['table-wrapper']}>
        {initialLoading ? (
          <PageLoader />
        ) : (
          <div>
            <div className={styles['filters-container']}>
              <div className={styles.filters}>
                <div className={styles['filter-item']}>
                  <div className={styles['filter-title']}>Managers</div>

                  <SelectFilter
                    options={managerFilterOptions}
                    selectedItems={selectedManager}
                    onHandleChange={onManagerChanged}
                    hasSelectAll={false}
                    DefaultItemRenderer={SingleSelectOption}
                  />
                </div>

                <div className={styles['filter-item']}>
                  <div className={styles['filter-title']}>Projects</div>

                  <SelectFilter
                    options={projectFilterOptions}
                    selectedItems={selectedProject}
                    onHandleChange={onProjectChanged}
                    hasSelectAll={false}
                    DefaultItemRenderer={SingleSelectOption}
                  />
                </div>

                <div className={styles['filter-item']}>
                  <div className={styles['filter-title']}>Employees</div>

                  <SelectFilter
                    options={employeeFilterOptions}
                    selectedItems={selectedEmployee}
                    onHandleChange={onEmployeeChanged}
                    hasSelectAll={true}
                  />
                </div>
              </div>

              <YearProgress
                selectedYear={selectedYear}
                selectedData={selectedDataForYearProgress}
                selectedOverallTab={selectedOverallTab}
                onOverallTabChanged={onOverallTabChanged}
                onFilteredTabChanged={onFilteredTabChanged}
                isFilterUnused={isFilterUnused}
              />
            </div>

            <div className={styles['table-container']}>
              <div className={styles['table-header-container']}>
                <div className={styles['table-loader']}>
                  <BarLoader
                    color={colors.mainColor}
                    height={2}
                    loading={dataFromServerLoading}
                    cssOverride={{
                      width: '100%',
                      position: 'relative'
                    }}
                  />
                </div>

                <div className={styles['table-header']}>
                  <div className={styles['employee-quantity']}>{filteredData.length} Employees</div>

                  <div className={styles['table-year-selector']}>
                    <YearSelector
                      onSelectedYearChanged={onYearChanged}
                      selectedYear={selectedYear}
                    />
                  </div>

                  <CsvGenerator data={filteredData} />
                </div>
              </div>

              {filteredData.length > 0 ? (
                <StatisticsTable
                  headerGroups={headerGroups}
                  rows={rows}
                  prepareRow={prepareRow}
                  getTableProps={getTableProps}
                  getTableBodyProps={getTableBodyProps}
                />
              ) : (
                <div className={classNames(styles.table, styles['not-found'])}>
                  No employees were found that match the selected criteria
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </OidcSecure>
  );
};

export const SecureStatistics = () => {
  const { oidcUser, oidcUserLoadingState } = useOidcUser();
  const { fetch } = useOidcFetch();

  return (
    <OidcSecure>
      {oidcUserLoadingState === OidcUserStatus.Loaded &&
        (oidcUser.EmployeeVacationAndSickLeave === EMPLOYEE_PERMISSION_LEVELS.viewAll ||
          oidcUser.EmployeeVacationAndSickLeave === EMPLOYEE_PERMISSION_LEVELS.viewAllAndEdit) && (
          <Statistics fetch={fetch} />
        )}
    </OidcSecure>
  );
};
