import React from 'react';
import { PropTypes } from 'prop-types';
import styles from './styles.module.scss';

export const SingleSelectOption = ({ checked, option, onClick }) => {
  return (
    <div className={styles['single-select']}>
      <span className={styles['option-label']}>{option.label}</span>
      <input type="checkbox" id="cb" onChange={onClick} checked={checked} />
      <label htmlFor="cb" className={styles.checkmark}></label>
    </div>
  );
};

SingleSelectOption.propTypes = {
  checked: PropTypes.bool.isRequired,
  option: PropTypes.shape({
    value: PropTypes.any,
    label: PropTypes.string,
    key: PropTypes.string,
    disabled: PropTypes.bool
  }).isRequired,
  onClick: PropTypes.func.isRequired
};
