import React, { useEffect, useState } from 'react';
import environment from '../../config';

const apiHeaderPost = {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json'
  }
};

export const useFetchSchedules = (projectIds = [], userIds = [], condition = true) => {
  const [tracks, setTracks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const responseUseMemoOptions = React.useMemo(() => {
    return {
      ...apiHeaderPost,

      body: JSON.stringify({
        projectIds: projectIds,
        userIds: userIds
      })
    };
  }, [projectIds, userIds]);

  const fetchData = async (url, requestOptions) => {
    const response = await fetch(url, requestOptions);

    return response.json();
  };

  const mapData = (data) => {
    const tracksMap = {};

    data.forEach((s) => {
      if (!tracksMap[s.owner.userName]) {
        tracksMap[s.owner.userName] = {
          id: s.owner.userName,
          title: s.owner.fullName,
          elements: {}
        };
      }

      tracksMap[s.owner.userName].elements[s.id] = {
        id: s.id,
        type: s.type,
        title:
          s.type === 'dayOff'
            ? `🌴 ${s.owner.fullName} Day off`
            : `🤒 ${s.owner.fullName} Sickness`,
        start: new Date(s.start),
        end: new Date(s.end),
        style: {
          backgroundColor: s.type === 'dayOff' ? '#60b23a' : '#f7d352'
        }
      };
    });

    const tracks = Object.values(tracksMap)
      .map((t) => ({
        ...t,
        elements: Object.values(t.elements)
      }))
      .sort((a, b) => a.id.localeCompare(b.id));

    return tracks;
  };

  useEffect(() => {
    if (condition) {
      fetchData(`${environment.apiEmployeeBaseUrl}/vacations/schedule`, responseUseMemoOptions)
        .then((response) => {
          return mapData(response);
        })
        .then((tracks) => {
          setTracks(tracks);
        })
        .catch((e) => {
          setError(e);
          console.log('Error:', e);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [condition, responseUseMemoOptions]);

  return { tracks, loading, error };
};
