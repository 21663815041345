export const formatData = (data) => {
  const currentlyWorkingEmployees = data.filter(
    (employee) => employee.totalUsedPercentPure !== 'NaN'
  );

  return currentlyWorkingEmployees?.map((employee) => {
    delete employee.transferredBalance;

    return {
      ...employee,
      expectedEoyVacationBalance: Math.round(employee.expectedEoyVacationBalance),
      totalAtThisYear: employee.totalAtThisYear.toFixed(1),
      totalUsedPercent: `${Math.round(employee.totalUsedPercent * 100)}%`,
      totalUsedPercentPure: `${Math.round(employee.totalUsedPercentPure * 100)}%`
    };
  });
};

export const getFormatedSelectedEmployee = (totalArr, arrWithSelectedItems) => {
  if (arrWithSelectedItems.length === 0 || arrWithSelectedItems.length === totalArr.length) {
    return totalArr;
  }

  let idArr = arrWithSelectedItems.map((obj) => obj.id);
  let result = totalArr.filter((person) => idArr.includes(person.id));

  return result;
};

export const namesOptions = (inputData) => {
  return inputData?.map(({ id, userName, fullName }) => {
    return {
      id: id ?? userName,
      value: `${fullName} (${id ?? userName}@leobit.com)`,
      label: `${fullName} (${id ?? userName}@leobit.com)`
    };
  });
};

export const projectNamesOptions = (inputData) => {
  return inputData?.map(({ id, name, type }) => {
    return {
      id,
      value: name,
      label: name,
      type
    };
  });
};
