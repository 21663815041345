export const countAverageValueByKey = (data = [], key) => {
  if (data.length === 0) {
    return 0;
  }

  const sum = data.reduce(function (total, currentValue) {
    return total + parseFloat(currentValue[key]);
  }, 0);

  return +(sum / data.length).toFixed(1);
};

export const countYearProgress = (selectedYear) => {
  const todaysYear = new Date().getFullYear();

  if (selectedYear < todaysYear) {
    return 100;
  }

  const leapYear = new Date(todaysYear, 1, 29).getDate() === 29;

  const currentDate = new Date();
  const newYearDate = new Date(todaysYear, 0, 1);

  const passedDays = Math.ceil(
    (currentDate.getTime() - newYearDate.getTime()) / (1000 * 60 * 60 * 24)
  );

  return leapYear ? +((passedDays / 366) * 100).toFixed(1) : +((passedDays / 365) * 100).toFixed(1);
};
