import { CSVLink } from 'react-csv';
import { PropTypes } from 'prop-types';
import styles from './styles.module.scss';

const csvHeadersDownload = [
  { label: 'Full Name', key: 'fullName' },
  { label: 'jan', key: 'jan' },
  { label: 'feb', key: 'feb' },
  { label: 'mar', key: 'mar' },
  { label: 'apr', key: 'apr' },
  { label: 'may', key: 'may' },
  { label: 'jun', key: 'jun' },
  { label: 'jul', key: 'jul' },
  { label: 'aug', key: 'aug' },
  { label: 'sep', key: 'sep' },
  { label: 'oct', key: 'oct' },
  { label: 'nov', key: 'nov' },
  { label: 'dec', key: 'dec' },
  { label: 'Total Used', key: 'totalUsed' },
  { label: 'Total This Year', key: 'totalAtThisYear' },
  { label: 'EOY', key: 'expectedEoyVacationBalance' },
  { label: 'Used', key: 'totalUsedPercent' },
  { label: 'Used Pure', key: 'totalUsedPercentPure' }
];

export const CsvGenerator = ({ data }) => {
  return (
    <CSVLink
      data={data}
      headers={csvHeadersDownload}
      filename={'vacation-statistics.csv'}
      className={styles['download-button']}>
      <button className={styles['download-button-icon']}>
        <i className="pi pi-file"></i>
      </button>
      Download
    </CSVLink>
  );
};

CsvGenerator.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      fullName: PropTypes.string.isRequired,
      apr: PropTypes.string,
      aug: PropTypes.string,
      dec: PropTypes.string,
      feb: PropTypes.string,
      jan: PropTypes.string,
      jul: PropTypes.string,
      jun: PropTypes.string,
      mar: PropTypes.string,
      may: PropTypes.string,
      nov: PropTypes.string,
      oct: PropTypes.string,
      sep: PropTypes.string,
      totalAtThisYear: PropTypes.string,
      totalUsed: PropTypes.number,
      totalUsedPercent: PropTypes.string,
      totalUsedPercentPure: PropTypes.string,
      expectedEoyVacationBalance: PropTypes.number
    })
  ).isRequired
};
