import moment from 'moment';

export const defineLabel = (employmentDate, standingYears) => {
  let label;

  if (!employmentDate) {
    label = 'T&M';
  } else {
    const today = moment();
    const startDate = moment(employmentDate);
    const daysDiff = today.diff(startDate, 'days');

    label = standingYears === 0 && daysDiff < 90 ? 'Trial' : '';
  }

  return label;
};
