import { PropTypes } from 'prop-types';
import { SickLeavesUsedDaysItem } from '../SickLeavesUsedDaysItem';
import { calculatePercentage, findTotalInRow } from '../VacationAndSickLeave/utils';
import styles from './styles.module.scss';
import '../../App.scss';

export const SickLeavesUsedDays = ({ sickLeaveData }) => {
  const {
    providedSickLeaveDaysWithoutCertificate,
    providedSickLeaveDaysWithCertificate,
    providedSickLeaveDaysToBeCompensated100,
    providedSickLeaveDaysToBeCompensated75,
    yearData
  } = sickLeaveData;

  const withoutCertificate = findTotalInRow(yearData, 'withoutCertificate');
  const withCertificate = findTotalInRow(yearData, 'withCertificate');
  const fullyPaid = findTotalInRow(yearData, 'fullyPaid');
  const partiallyPaid = findTotalInRow(yearData, 'partiallyPaid');

  const withoutCertificatePercentage = calculatePercentage(
    withoutCertificate,
    providedSickLeaveDaysWithoutCertificate
  );

  const withCertificatePercentage = calculatePercentage(
    withCertificate,
    providedSickLeaveDaysWithCertificate
  );

  const fullyPaidPercentage = calculatePercentage(
    fullyPaid,
    providedSickLeaveDaysToBeCompensated100
  );

  const partiallyPaidPercentage = calculatePercentage(
    partiallyPaid,
    providedSickLeaveDaysToBeCompensated75
  );

  return (
    <div className={styles['sick-leaves-used-days']}>
      <h3 className="vacation-and-sick-leave-section-title">Sick-leaves used, days</h3>

      <div className={styles['sick-leaves-used-days__content']}>
        <SickLeavesUsedDaysItem
          currentSickLeaveDays={withoutCertificate}
          providedSickLeaveDays={providedSickLeaveDaysWithoutCertificate}
          percentage={withoutCertificatePercentage}
          title="Without medical certificate"
        />

        <SickLeavesUsedDaysItem
          currentSickLeaveDays={withCertificate}
          providedSickLeaveDays={providedSickLeaveDaysWithCertificate}
          percentage={withCertificatePercentage}
          title="With medical certificate"
        />

        <SickLeavesUsedDaysItem
          currentSickLeaveDays={fullyPaid}
          providedSickLeaveDays={providedSickLeaveDaysToBeCompensated100}
          percentage={fullyPaidPercentage}
          title="To be compensated 100%"
        />

        <SickLeavesUsedDaysItem
          currentSickLeaveDays={partiallyPaid}
          providedSickLeaveDays={providedSickLeaveDaysToBeCompensated75}
          percentage={partiallyPaidPercentage}
          title="To be compensated 75%"
        />
      </div>
    </div>
  );
};

SickLeavesUsedDays.propTypes = {
  sickLeaveData: PropTypes.shape({
    providedSickLeaveDaysWithoutCertificate: PropTypes.number.isRequired,
    providedSickLeaveDaysWithCertificate: PropTypes.number.isRequired,
    providedSickLeaveDaysToBeCompensated100: PropTypes.number.isRequired,
    providedSickLeaveDaysToBeCompensated75: PropTypes.number.isRequired,
    currentSickLeaveBalance: PropTypes.number.isRequired,
    yearData: PropTypes.shape({
      january: PropTypes.shape({
        withoutCertificate: PropTypes.number,
        withCertificate: PropTypes.number,
        fullyPaid: PropTypes.number,
        partiallyPaid: PropTypes.number
      }),
      february: PropTypes.shape({
        withoutCertificate: PropTypes.number,
        withCertificate: PropTypes.number,
        fullyPaid: PropTypes.number,
        partiallyPaid: PropTypes.number
      }),
      march: PropTypes.shape({
        withoutCertificate: PropTypes.number,
        withCertificate: PropTypes.number,
        fullyPaid: PropTypes.number,
        partiallyPaid: PropTypes.number
      }),
      april: PropTypes.shape({
        withoutCertificate: PropTypes.number,
        withCertificate: PropTypes.number,
        fullyPaid: PropTypes.number,
        partiallyPaid: PropTypes.number
      }),
      may: PropTypes.shape({
        withoutCertificate: PropTypes.number,
        withCertificate: PropTypes.number,
        fullyPaid: PropTypes.number,
        partiallyPaid: PropTypes.number
      }),
      june: PropTypes.shape({
        withoutCertificate: PropTypes.number,
        withCertificate: PropTypes.number,
        fullyPaid: PropTypes.number,
        partiallyPaid: PropTypes.number
      }),
      july: PropTypes.shape({
        withoutCertificate: PropTypes.number,
        withCertificate: PropTypes.number,
        fullyPaid: PropTypes.number,
        partiallyPaid: PropTypes.number
      }),
      august: PropTypes.shape({
        withoutCertificate: PropTypes.number,
        withCertificate: PropTypes.number,
        fullyPaid: PropTypes.number,
        partiallyPaid: PropTypes.number
      }),
      september: PropTypes.shape({
        withoutCertificate: PropTypes.number,
        withCertificate: PropTypes.number,
        fullyPaid: PropTypes.number,
        partiallyPaid: PropTypes.number
      }),
      october: PropTypes.shape({
        withoutCertificate: PropTypes.number,
        withCertificate: PropTypes.number,
        fullyPaid: PropTypes.number,
        partiallyPaid: PropTypes.number
      }),
      november: PropTypes.shape({
        withoutCertificate: PropTypes.number,
        withCertificate: PropTypes.number,
        fullyPaid: PropTypes.number,
        partiallyPaid: PropTypes.number
      }),
      december: PropTypes.shape({
        withoutCertificate: PropTypes.number,
        withCertificate: PropTypes.number,
        fullyPaid: PropTypes.number,
        partiallyPaid: PropTypes.number
      })
    })
  })
};
