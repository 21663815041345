import { useEffect, useMemo, useState } from 'react';
import { OidcSecure, OidcUserStatus, useOidcFetch, useOidcUser } from '@axa-fr/react-oidc';
import { VacationReport } from '../VacationReport';
import { YearSelector } from '../YearSelector';
import { PageLoader } from '../PageLoader';
import { SickLeaveUsage } from '../SickLeaveUsage';
import { SelectFilter } from '../SelectFilter';
import { SingleSelectOption } from '../SingleSelectOption';
import { namesOptions } from '../Statistics/utils';
import { useVacationAndSickLeaveData } from './useVacationAndSickLeaveData';
import { handleMultipleSelect } from '../../common/utils/generalUtils';
import { EMPLOYEE_PERMISSION_LEVELS } from '../../common/const/generalConst';
import { processIdsFromQueryParam, updateUrlParams } from '../../common/utils/queryUtils';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './styles.module.scss';

const VacationAndSickLeave = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { oidcUser, oidcUserLoadingState } = useOidcUser();
  const { EmployeeVacationAndSickLeave, name, sub } = oidcUser ?? {};

  const currentUser = useMemo(() => namesOptions([{ fullName: name, userName: sub }]), [name, sub]);

  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedEmployee, setSelectedEmployee] = useState([]);

  const isSpecialPermissionUser =
    oidcUserLoadingState === OidcUserStatus.Loaded &&
    (EmployeeVacationAndSickLeave === EMPLOYEE_PERMISSION_LEVELS.viewAll ||
      EmployeeVacationAndSickLeave === EMPLOYEE_PERMISSION_LEVELS.viewAllAndEdit);

  const isStandartPermissionUser =
    oidcUserLoadingState === OidcUserStatus.Loaded &&
    EmployeeVacationAndSickLeave !== EMPLOYEE_PERMISSION_LEVELS.viewAll &&
    EmployeeVacationAndSickLeave !== EMPLOYEE_PERMISSION_LEVELS.viewAllAndEdit;

  // transform multiple select into single select
  handleMultipleSelect(selectedEmployee, setSelectedEmployee);

  const {
    pageInitialLoading,
    employeesData,
    filteredVacationData,
    filteredSickLeaveData,
    checkedEmployeeVacationLoading,
    checkedEmployeeSickLeaveLoading
  } = useVacationAndSickLeaveData(
    selectedYear,
    selectedEmployee,
    isStandartPermissionUser,
    isSpecialPermissionUser
  );

  const employeeFilterOptions = useMemo(() => {
    return namesOptions(employeesData);
  }, [employeesData]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    if (isSpecialPermissionUser) {
      const displayedEmployees = processIdsFromQueryParam(
        queryParams,
        'employee',
        employeeFilterOptions
      );

      setSelectedEmployee(!!displayedEmployees.length ? displayedEmployees : currentUser);
    } else {
      setSelectedEmployee(currentUser);
    }

    const selectedYearFromUrl = processIdsFromQueryParam(queryParams, 'year');
    const displayedSelectedYear =
      selectedYearFromUrl === null ? new Date().getFullYear() : +selectedYearFromUrl;
    setSelectedYear(displayedSelectedYear);
  }, [currentUser, employeeFilterOptions, isSpecialPermissionUser, location.search]);

  const onYearChanged = (year) => {
    setSelectedYear(year);
    updateUrlParams([{ paramName: 'year', paramValue: year }], navigate);
  };

  const onEmployeeChanged = (employee) => {
    if (employee.length !== 0) {
      setSelectedEmployee(employee);

      updateUrlParams(
        [
          {
            paramName: 'employee',
            paramValue: employee.length > 1 ? employee.slice(1) : employee
          }
        ],
        navigate
      );
    }
  };

  return (
    <>
      {pageInitialLoading ? (
        <PageLoader />
      ) : (
        <div className={styles['vacation-and-sick-leave']}>
          {filteredVacationData.length !== 0 && (
            <>
              <div className={styles['vacation-and-sick-leave__header']}>
                <h2 className={styles['vacation-and-sick-leave__title']}>Vacation report</h2>

                <YearSelector
                  onSelectedYearChanged={onYearChanged}
                  withBorder={true}
                  selectedYear={selectedYear}
                />

                {isSpecialPermissionUser && (
                  <div className={styles['filter-item']}>
                    <SelectFilter
                      options={employeeFilterOptions}
                      selectedItems={selectedEmployee}
                      onHandleChange={onEmployeeChanged}
                      hasSelectAll={false}
                      DefaultItemRenderer={SingleSelectOption}
                      additionalClassName={true}
                    />
                  </div>
                )}
              </div>

              <VacationReport
                vacationData={filteredVacationData}
                selectedYear={selectedYear}
                loading={checkedEmployeeVacationLoading}
                isSpecialPermissionUser={isSpecialPermissionUser}
              />

              <h2 className={styles['vacation-and-sick-leave__title']}>Sick-leave usage</h2>

              {filteredSickLeaveData.length !== 0 && (
                <SickLeaveUsage
                  sickLeaveData={filteredSickLeaveData}
                  selectedYear={selectedYear}
                  loading={checkedEmployeeSickLeaveLoading}
                  isSpecialPermissionUser={isSpecialPermissionUser}
                />
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

export const SecureVacationAndSickLeave = () => {
  const { fetch } = useOidcFetch();

  return (
    <OidcSecure>
      <VacationAndSickLeave fetch={fetch} />
    </OidcSecure>
  );
};
