import { PropTypes } from 'prop-types';
import { CircleProgressbar } from '../CircleProgressBar';
import styles from './styles.module.scss';

export const SickLeavesUsedDaysItem = ({
  currentSickLeaveDays,
  providedSickLeaveDays,
  percentage,
  title
}) => {
  return (
    <div className={styles['sick-leave-used-item']}>
      <div className={styles['sick-leave-used-item__progress-bar']}>
        <CircleProgressbar percentage={percentage} styles={{ width: 40, height: 40 }} />

        <div className={styles['sick-leave-used-item__progress-number']}>
          <b>
            {percentage}
            <b className={styles['sick-leave-used-item__progress-percentage']}>%</b>
          </b>
        </div>
      </div>

      <div className={styles['sick-leave-used-item__content']}>
        <div className={styles['sick-leave-used-item__info']}>
          {currentSickLeaveDays} / {providedSickLeaveDays}
        </div>
        <div className={styles['sick-leave-used-item__title']}>{title}</div>
      </div>
    </div>
  );
};

SickLeavesUsedDaysItem.propTypes = {
  currentSickLeaveDays: PropTypes.string,
  providedSickLeaveDays: PropTypes.number,
  percentage: PropTypes.number,
  title: PropTypes.string
};
