import { useEffect, useState } from 'react';

export const useFilterSchedules = (allSchedules, selectedProjectTypesOptions, isChecked) => {
  const [schedules, setSchedules] = useState([]);

  const filterByOngoing = (items, types) => {
    let employeesOnGoing = [];

    items?.map((i) => ({
      ...i,
      elements: i.elements.filter((e) => {
        if (types.includes(e.type)) {
          const from = new Date(e.start);
          const to = new Date(e.end);
          const currentDate = new Date();

          const filterCondition = currentDate >= from && currentDate <= to;

          if (filterCondition) {
            employeesOnGoing.push(i);
          }

          return filterCondition;
        }

        return false;
      })
    }));

    return employeesOnGoing;
  };

  useEffect(() => {
    const filterByType = (items, types) => {
      if (isChecked) {
        return filterByOngoing(items, types);
      }

      return items.map((i) => ({
        ...i,
        elements: i.elements.filter((e) => types.includes(e.type))
      }));
    };

    const res = filterByType(
      allSchedules,
      selectedProjectTypesOptions.map((x) => x.value)
    );

    const filteredResult = res.filter(
      (employeeAfterFilerByType) => employeeAfterFilerByType.elements.length > 0
    );

    setSchedules(filteredResult);
  }, [allSchedules, isChecked, selectedProjectTypesOptions]);

  return { schedules };
};
