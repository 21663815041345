import React from 'react';
import { DetailsButton } from '../DetailsButton';

export const statisticsColumns = [
  {
    Header: 'Full Name',
    accessor: 'fullName',
    filter: 'includesSome',
    minWidth: 230,
    width: 300,
    Cell: (props) => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img
          src={`https://api.employee.leobit.co/photos-small/${props.row.original.id}.png`}
          alt="employee-avatar"
          style={{ width: '32px', marginRight: '12px' }}
        />
        {props.value}
      </div>
    )
  },
  {
    Header: 'Total Used',
    accessor: 'totalUsed'
  },
  {
    Header: 'Total This Year',
    accessor: 'totalAtThisYear'
  },
  {
    Header: 'EOY',
    accessor: 'expectedEoyVacationBalance'
  },
  {
    Header: 'Used',
    accessor: 'totalUsedPercent'
  },
  {
    Header: 'Used Pure',
    accessor: 'totalUsedPercentPure'
  },
  {
    Header: '',
    accessor: 'action',
    disableSortBy: true,
    Cell: (props) => <DetailsButton dataForModal={props.row.original} />
  }
];
