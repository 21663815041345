import React from 'react';
import { useCallback } from 'react';
import { PropTypes } from 'prop-types';
import { findTotalInRow, months } from '../VacationAndSickLeave/utils';
import classNames from 'classnames';
import styles from './styles.module.scss';
import '../../App.scss';

const VacationAndSickLeaveTable = ({ yearData, tableRowData, tableName, selectedYear }) => {
  const currentMonth = new Date().getMonth();
  const currentYear = new Date().getFullYear();

  const renderHeaderRow = useCallback(
    () => (
      <tr>
        <th>&nbsp;</th>
        {months.map((month) => (
          <th key={month}>{month.slice(0, 1).toUpperCase() + month.slice(1, 3).toLowerCase()}</th>
        ))}
        <th>Total for the year</th>
      </tr>
    ),
    []
  );

  const renderDataRow = useCallback(
    (rowName, getDataByKey) => (
      <tr>
        <td className={classNames(styles['row-main-cell'], styles['row-title'])}>{rowName}</td>

        {months.map((month, index) => {
          const value = yearData[month][getDataByKey];

          return (
            <td
              className={classNames({
                [styles['row-data-previous']]: selectedYear < currentYear || currentMonth > index
              })}
              key={index}>
              {value ? value > 0 ? <b>{value.toFixed(2)}</b> : value.toFixed(2) : '0.00'}
            </td>
          );
        })}

        <td className={styles['row-main-cell']}>
          <b>{findTotalInRow(yearData, getDataByKey)}</b>
        </td>
      </tr>
    ),
    [yearData, currentMonth, currentYear, selectedYear]
  );

  return (
    <>
      {yearData && (
        <div className={styles['table-container']}>
          <h3 className="vacation-and-sick-leave-section-title">{tableName}</h3>

          <table className={styles['custom-table']}>
            <thead>{renderHeaderRow()}</thead>
            <tbody>
              {tableRowData.map(({ title, getDataByKey }) => (
                <React.Fragment key={getDataByKey}>
                  {renderDataRow(title, getDataByKey)}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default VacationAndSickLeaveTable;

VacationAndSickLeaveTable.propTypes = {
  selectedYear: PropTypes.number,
  tableName: PropTypes.string,
  tableRowData: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      getDataByKey: PropTypes.string
    })
  ),
  yearData: PropTypes.shape({
    january: PropTypes.oneOfType([
      PropTypes.shape({
        accrual: PropTypes.number.isRequired,
        used: PropTypes.number,
        usedUnpaid: PropTypes.number
      }),
      PropTypes.shape({
        withoutCertificate: PropTypes.number,
        withCertificate: PropTypes.number,
        fullyPaid: PropTypes.number,
        partiallyPaid: PropTypes.number
      })
    ]),
    february: PropTypes.oneOfType([
      PropTypes.shape({
        accrual: PropTypes.number.isRequired,
        used: PropTypes.number,
        usedUnpaid: PropTypes.number
      }),
      PropTypes.shape({
        withoutCertificate: PropTypes.number,
        withCertificate: PropTypes.number,
        fullyPaid: PropTypes.number,
        partiallyPaid: PropTypes.number
      })
    ]),
    march: PropTypes.oneOfType([
      PropTypes.shape({
        accrual: PropTypes.number.isRequired,
        used: PropTypes.number,
        usedUnpaid: PropTypes.number
      }),
      PropTypes.shape({
        withoutCertificate: PropTypes.number,
        withCertificate: PropTypes.number,
        fullyPaid: PropTypes.number,
        partiallyPaid: PropTypes.number
      })
    ]),
    april: PropTypes.oneOfType([
      PropTypes.shape({
        accrual: PropTypes.number.isRequired,
        used: PropTypes.number,
        usedUnpaid: PropTypes.number
      }),
      PropTypes.shape({
        withoutCertificate: PropTypes.number,
        withCertificate: PropTypes.number,
        fullyPaid: PropTypes.number,
        partiallyPaid: PropTypes.number
      })
    ]),
    may: PropTypes.oneOfType([
      PropTypes.shape({
        accrual: PropTypes.number.isRequired,
        used: PropTypes.number,
        usedUnpaid: PropTypes.number
      }),
      PropTypes.shape({
        withoutCertificate: PropTypes.number,
        withCertificate: PropTypes.number,
        fullyPaid: PropTypes.number,
        partiallyPaid: PropTypes.number
      })
    ]),
    june: PropTypes.oneOfType([
      PropTypes.shape({
        accrual: PropTypes.number.isRequired,
        used: PropTypes.number,
        usedUnpaid: PropTypes.number
      }),
      PropTypes.shape({
        withoutCertificate: PropTypes.number,
        withCertificate: PropTypes.number,
        fullyPaid: PropTypes.number,
        partiallyPaid: PropTypes.number
      })
    ]),
    july: PropTypes.oneOfType([
      PropTypes.shape({
        accrual: PropTypes.number.isRequired,
        used: PropTypes.number,
        usedUnpaid: PropTypes.number
      }),
      PropTypes.shape({
        withoutCertificate: PropTypes.number,
        withCertificate: PropTypes.number,
        fullyPaid: PropTypes.number,
        partiallyPaid: PropTypes.number
      })
    ]),
    august: PropTypes.oneOfType([
      PropTypes.shape({
        accrual: PropTypes.number.isRequired,
        used: PropTypes.number,
        usedUnpaid: PropTypes.number
      }),
      PropTypes.shape({
        withoutCertificate: PropTypes.number,
        withCertificate: PropTypes.number,
        fullyPaid: PropTypes.number,
        partiallyPaid: PropTypes.number
      })
    ]),
    september: PropTypes.oneOfType([
      PropTypes.shape({
        accrual: PropTypes.number.isRequired,
        used: PropTypes.number,
        usedUnpaid: PropTypes.number
      }),
      PropTypes.shape({
        withoutCertificate: PropTypes.number,
        withCertificate: PropTypes.number,
        fullyPaid: PropTypes.number,
        partiallyPaid: PropTypes.number
      })
    ]),
    october: PropTypes.oneOfType([
      PropTypes.shape({
        accrual: PropTypes.number.isRequired,
        used: PropTypes.number,
        usedUnpaid: PropTypes.number
      }),
      PropTypes.shape({
        withoutCertificate: PropTypes.number,
        withCertificate: PropTypes.number,
        fullyPaid: PropTypes.number,
        partiallyPaid: PropTypes.number
      })
    ]),
    november: PropTypes.oneOfType([
      PropTypes.shape({
        accrual: PropTypes.number.isRequired,
        used: PropTypes.number,
        usedUnpaid: PropTypes.number
      }),
      PropTypes.shape({
        withoutCertificate: PropTypes.number,
        withCertificate: PropTypes.number,
        fullyPaid: PropTypes.number,
        partiallyPaid: PropTypes.number
      })
    ]),
    december: PropTypes.oneOfType([
      PropTypes.shape({
        accrual: PropTypes.number.isRequired,
        used: PropTypes.number,
        usedUnpaid: PropTypes.number
      }),
      PropTypes.shape({
        withoutCertificate: PropTypes.number,
        withCertificate: PropTypes.number,
        fullyPaid: PropTypes.number,
        partiallyPaid: PropTypes.number
      })
    ])
  })
};
