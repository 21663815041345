import VacationAndSickLeaveTable from '../VacationAndSickLeaveTable';
import { SickLeavesUsedDays } from '../SickLeavesUsedDays';
import { SectionLoader } from '../SectionLoader';
import { PropTypes } from 'prop-types';
import styles from './styles.module.scss';
import '../../App.scss';

const tableRowData = [
  { title: 'Without', getDataByKey: 'withoutCertificate' },
  { title: 'With', getDataByKey: 'withCertificate' }
];

export const SickLeaveUsage = ({
  sickLeaveData,
  selectedYear,
  loading,
  isSpecialPermissionUser
}) => {
  const { yearData } = sickLeaveData;

  return (
    <>
      {Object.keys(yearData).length !== 0 ? (
        <div className="vacation-and-sick-leave-section-wrapper">
          {isSpecialPermissionUser && <SectionLoader loading={loading} />}

          <SickLeavesUsedDays sickLeaveData={sickLeaveData} />

          <VacationAndSickLeaveTable
            yearData={yearData}
            tableRowData={tableRowData}
            tableName="Sick-leave without/with medical certificate usage, days"
            selectedYear={selectedYear}
          />
        </div>
      ) : (
        <div className={styles['vacation-and-sick-leave-section-warning']}>
          There is no data available
        </div>
      )}
    </>
  );
};

SickLeaveUsage.propTypes = {
  isSpecialPermissionUser:  PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  selectedYear: PropTypes.number,
  sickLeaveData: PropTypes.shape({
    providedSickLeaveDaysWithoutCertificate: PropTypes.number.isRequired,
    providedSickLeaveDaysWithCertificate: PropTypes.number.isRequired,
    providedSickLeaveDaysToBeCompensated100: PropTypes.number.isRequired,
    providedSickLeaveDaysToBeCompensated75: PropTypes.number.isRequired,
    currentSickLeaveBalance: PropTypes.number.isRequired,
    yearData: PropTypes.shape({
      january: PropTypes.shape({
        withoutCertificate: PropTypes.number,
        withCertificate: PropTypes.number,
        fullyPaid: PropTypes.number,
        partiallyPaid: PropTypes.number
      }),
      february: PropTypes.shape({
        withoutCertificate: PropTypes.number,
        withCertificate: PropTypes.number,
        fullyPaid: PropTypes.number,
        partiallyPaid: PropTypes.number
      }),
      march: PropTypes.shape({
        withoutCertificate: PropTypes.number,
        withCertificate: PropTypes.number,
        fullyPaid: PropTypes.number,
        partiallyPaid: PropTypes.number
      }),
      april: PropTypes.shape({
        withoutCertificate: PropTypes.number,
        withCertificate: PropTypes.number,
        fullyPaid: PropTypes.number,
        partiallyPaid: PropTypes.number
      }),
      may: PropTypes.shape({
        withoutCertificate: PropTypes.number,
        withCertificate: PropTypes.number,
        fullyPaid: PropTypes.number,
        partiallyPaid: PropTypes.number
      }),
      june: PropTypes.shape({
        withoutCertificate: PropTypes.number,
        withCertificate: PropTypes.number,
        fullyPaid: PropTypes.number,
        partiallyPaid: PropTypes.number
      }),
      july: PropTypes.shape({
        withoutCertificate: PropTypes.number,
        withCertificate: PropTypes.number,
        fullyPaid: PropTypes.number,
        partiallyPaid: PropTypes.number
      }),
      august: PropTypes.shape({
        withoutCertificate: PropTypes.number,
        withCertificate: PropTypes.number,
        fullyPaid: PropTypes.number,
        partiallyPaid: PropTypes.number
      }),
      september: PropTypes.shape({
        withoutCertificate: PropTypes.number,
        withCertificate: PropTypes.number,
        fullyPaid: PropTypes.number,
        partiallyPaid: PropTypes.number
      }),
      october: PropTypes.shape({
        withoutCertificate: PropTypes.number,
        withCertificate: PropTypes.number,
        fullyPaid: PropTypes.number,
        partiallyPaid: PropTypes.number
      }),
      november: PropTypes.shape({
        withoutCertificate: PropTypes.number,
        withCertificate: PropTypes.number,
        fullyPaid: PropTypes.number,
        partiallyPaid: PropTypes.number
      }),
      december: PropTypes.shape({
        withoutCertificate: PropTypes.number,
        withCertificate: PropTypes.number,
        fullyPaid: PropTypes.number,
        partiallyPaid: PropTypes.number
      })
    })
  })
};
