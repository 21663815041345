export const updateUrlParams = (dataToSet, navigate) => {
  const queryParams = new URLSearchParams(window.location.search);
  const updatedData = {};

  queryParams.forEach((value, key) => {
    updatedData[key] = value;
  });

  dataToSet.forEach(({ paramName, paramValue, allOptions, isBoolean }) => {
    if (paramName === undefined && paramValue === undefined) {
      return;
    }

    if (paramValue === null || paramValue.length === 0) {
      delete updatedData[paramName];
    } else {
      if (isBoolean) {
        updatedData[paramName] = paramValue.toString();
      } else if (!!paramValue.length) {
        const valueToAppend = paramValue.map((option) => option.id).join(',');

        const allOptionsSelected = allOptions && paramValue.length === allOptions.length;

        if (allOptionsSelected) {
          delete updatedData[paramName];
        } else {
          updatedData[paramName] = valueToAppend;
        }
      } else {
        updatedData[paramName] = paramValue;
      }
    }
  });

  const newQueryStringParams = new URLSearchParams(updatedData);

  navigate(`?${newQueryStringParams.toString()}`);
};

export const processIdsFromQueryParam = (queryParams, paramName, optionArray) => {
  const ids = queryParams.get(paramName)?.split(',') || [];

  if (!optionArray) {
    return queryParams.get(paramName);
  }

  const result =
    ids.length === 0 ? [] : optionArray.filter((option) => ids.includes(option.id.toString()));

  return result;
};
